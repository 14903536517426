<template>
  <div>
    <template v-if="solution && workshop">
      <evaluation-widget
        :solution="solution"
        :workshop="workshop"
        :game="true"
      ></evaluation-widget>
    </template>
  </div>
</template>

<script>
import EvaluationWidget from "../components/EvaluationWidget";
export default {
  components: { EvaluationWidget },
  data() {
    return {
      solution: this.$store.state.solution,
      workshop: this.$store.state.currentProject,
    };
  },
};
</script>
